import { OrbitControls } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import * as React from "react";
import { TextureLoader } from "three";
import Card from "./Card";

const Scene: React.FC = () => {
  const { scene } = useThree();
  const backgroundTexture = useLoader(TextureLoader, "textures/background.png");
  scene.background = backgroundTexture;

  return (
    <>
      <OrbitControls makeDefault />
      <Card
        name="Michael Cavallaro"
        jobTitle="Senior Software Engineer"
        footer="@Cavallando on GitHub"
        summary={`I am a passionate, energetic, and experienced
        software engineer with a genuine love for
        creating innovative solutions. I bring
        a contagious enthusiasm and a deep commitment
        to excellence in every project I undertake.
        
        I hope to work with you soon! \n\n\n- Mike`}
      />
    </>
  );
};

export default Scene;
