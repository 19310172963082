import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { logEvent } from "firebase/analytics";
import { useMemo, useRef } from "react";
import { Vector3 } from "three";
import Scene from "./components/Scene";
import { analytics } from "./lib/firebase";

function App() {
  const canvas = useRef<HTMLCanvasElement | null>(null);

  const handleDownloadResumeClick = () => {
    logEvent(analytics, "download_resume");
  };

  const cameraPosition = useMemo(() => new Vector3(1, 1, 150), []);
  const cameraSettings = {
    fov: 50,
    position: cameraPosition,
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Canvas camera={cameraSettings} ref={canvas} gl={{ antialias: true }}>
        <OrbitControls makeDefault />
        <Scene />
      </Canvas>
      <div id="download">
        <a
          href="files/MichaelCavallaroResume.pdf"
          download="MichaelCavallaroResume.pdf"
          onClick={handleDownloadResumeClick}
        >
          ⬇️ Download Resume
        </a>
      </div>
    </div>
  );
}

export default App;
