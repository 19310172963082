import { Center, Extrude, Text3D, useCursor } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { FC, useMemo, useRef, useState } from "react";
import { Group, TextureLoader, Vector3 } from "three";
import { createRoundedRect } from "../meshes/rounded-rect";

export const CardDimensions: Dimensions = {
  width: 75,
  height: 50,
  radius: 5,
  y: -25,
  x: -37.5,
  depth: 1,
};

type Dimensions = {
  width: number;
  height: number;
  radius: number;
  y: number;
  x: number;
  depth: number;
};

type CardProps = {
  dimensions?: Dimensions;
  name: string;
  jobTitle: string;
  footer?: string;
  summary?: string;
};

const Card: FC<CardProps> = ({
  dimensions,
  name,
  jobTitle,
  footer,
  summary,
}) => {
  const { width, height, radius, y, x, depth } = dimensions ?? CardDimensions;

  const [hover, setHover] = useState(false);
  useCursor(hover, "pointer", "auto");

  const texture = useLoader(TextureLoader, "textures/card.jpg");
  const fontTexture = useLoader(TextureLoader, "textures/text.jpg");

  const group = useRef<Group | null>(null);
  const footerText = useRef<Group | null>(null);

  useFrame(({ clock }) => {
    if (group.current) {
      group.current.rotation.y = Math.sin(clock.getElapsedTime()) * 0.5;
    }

    if (footerText.current) {
      footerText.current.scale.y = hover ? 1.1 : 1;
      footerText.current.scale.x = hover ? 1.1 : 1;
    }
  });

  const nameTextVector = useMemo(
    () => new Vector3(undefined, height / 5, depth),
    [height]
  );
  const jobTextVector = useMemo(
    () => new Vector3(undefined, nameTextVector.y - 10, depth),
    [nameTextVector.y]
  );
  const footerTextVector = useMemo(
    () => new Vector3(undefined, jobTextVector.y - 20, depth),
    [nameTextVector.y]
  );
  const summaryTextVector = useMemo(
    () => new Vector3(-1, 1, 0),
    [nameTextVector.y]
  );

  const summaryTextStr = `I am a passionate, energetic, and experienced
software engineer with a genuine love for
creating innovative solutions. I bring
a contagious enthusiasm and a deep commitment
to excellence in every project I undertake.

I hope to work with you soon! \n\n\n- Mike`;

  return (
    <group ref={group}>
      <Extrude
        args={[
          createRoundedRect(x, y, width, height, radius),
          { bevelEnabled: true, bevelSize: 1, depth },
        ]}
      >
        <meshMatcapMaterial matcap={texture} />
      </Extrude>
      <Center position={nameTextVector}>
        <Text3D size={5} height={1} font="fonts/Roboto.json">
          {name}
          <meshMatcapMaterial matcap={fontTexture} />
        </Text3D>
      </Center>
      <Center position={jobTextVector}>
        <Text3D size={3} height={1} font="fonts/Roboto.json">
          {jobTitle}
          <meshMatcapMaterial matcap={fontTexture} />
        </Text3D>
      </Center>

      {footer && (
        <Center
          ref={footerText}
          position={footerTextVector}
          onClick={() => window.open("https://github.com/Cavallando", "_blank")}
          onPointerOver={setHover.bind(this, true)}
          onPointerOut={setHover.bind(this, false)}
        >
          <Text3D size={2} height={1} font="fonts/Roboto.json">
            {footer}
            <meshMatcapMaterial matcap={fontTexture} />
          </Text3D>
        </Center>
      )}
      {summary && (
        <Center scale={new Vector3(-1, 1, 1)} position={summaryTextVector}>
          <Text3D size={2} height={1} font="fonts/Roboto.json">
            {summaryTextStr}
            <meshMatcapMaterial matcap={fontTexture} />
          </Text3D>
        </Center>
      )}
    </group>
  );
};

export default Card;
