import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDwACAlg9GFHMYwk2h8FhwaCmEh8wT36bs",
  authDomain: "resume-f71f6.firebaseapp.com",
  projectId: "resume-f71f6",
  storageBucket: "resume-f71f6.appspot.com",
  messagingSenderId: "942337378901",
  appId: "1:942337378901:web:ad6b8656cd1a5d5ca9a2dc",
  measurementId: "G-BL7HNLPEH8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true;
